import { simplicateApiV3, createTagTypes } from "@simplicate/api-client";
import { CubeDimension } from "../../../types";
import { convertStringToCubeDimension } from "../../hooks";

type Filter = {
  valueColumn: CubeDimension;
  labelColumn: CubeDimension;
  value: string;
  label: string;
};

type ApiFilter = Omit<Filter, "labelColumn" | "valueColumn"> & {
  valueColumn: string;
  labelColumn: string;
};

type PersistedColumn = {
  column: string;
  order: number;
  visible: boolean;
};

type ColumnOrder = {
  column: string;
  direction: "asc" | "desc";
};

type FilterPreset = {
  id: number;
  dashboardId: string;
  name: string;
  columns: PersistedColumn[];
  order: ColumnOrder[];
  filters: Filter[];
};

type ApiFilterPreset = Omit<FilterPreset, "filters"> & {
  filters: ApiFilter[];
};

const filterPresetTags = createTagTypes({
  tagPrefix: "FilterPreset",
  tags: ["filterPreset"],
});

const endpointsV3 = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(filterPresetTags) }).injectEndpoints({
  endpoints: (builder) => ({
    getFilterPresetsList: builder.query<FilterPreset[], string>({
      query: (dashboardId) => ({
        url: `/insight.filterPreset.list`,
        params: {
          dashboardId,
        },
      }),
      transformResponse: (result: ApiFilterPreset[]) => {
        return result.map((filterPreset) => ({
          ...filterPreset,
          filters: filterPreset.filters.map((filter) => ({
            ...filter,
            labelColumn: convertStringToCubeDimension(filter.labelColumn),
            valueColumn: convertStringToCubeDimension(filter.valueColumn),
          })),
        }));
      },
      providesTags: [filterPresetTags.filterPreset],
    }),

    getFilterPreset: builder.query<FilterPreset, number>({
      query: (viewId) => ({
        url: `/insight.filterPreset.get/${viewId}`,
      }),
    }),

    createFilterPreset: builder.mutation<ApiFilterPreset, Partial<ApiFilterPreset>>({
      query: (data) => ({
        url: "/insight.filterPreset.create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [filterPresetTags.filterPreset],
    }),

    updateFilterPreset: builder.mutation<ApiFilterPreset, ApiFilterPreset>({
      query: (data) => ({
        url: `/insight.filterPreset.update/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [filterPresetTags.filterPreset],
    }),
  }),
});

export const {
  useGetFilterPresetsListQuery,
  useGetFilterPresetQuery,
  useCreateFilterPresetMutation,
  useUpdateFilterPresetMutation,
} = endpointsV3;
