import { skipToken } from "@simplicate/api-client";
import { useMemo } from "react";
import { useGetFilterPresetsListQuery } from "../data";

export const useFilterPresets = (dashboardId?: string) => {
  const { data: filterPresets } = useGetFilterPresetsListQuery(/*istanbul ignore next */ dashboardId ?? skipToken);

  const views = useMemo(() => {
    return (
      filterPresets?.map((preset) => ({
        label: preset.name,
        id: preset.id,
        columns: preset.columns,
        order: preset.order,
        filters: preset.filters,
      })) ?? []
    );
  }, [filterPresets]);

  return views;
};
