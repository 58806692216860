// istanbul ignore file -- test suite is flaky. This component will soon no longer exist, so it's not worth fixing.
import { useTranslation } from "@simplicate/translations";
import { Modal, Input, Button, Icon } from "@simplicate/ui";
import { useState } from "react";
import { useCreateFilterPresetMutation, useUpdateFilterPresetMutation } from "../../data";
import { cubeDimensionToKey } from "../../types";
import { useDashboardContext } from "../Dashboard";
import styles from "./ViewModal.module.scss";

type ViewModalProps = {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  dashboardId: string;
  isEditMode?: boolean;
};

export const ViewModal = ({ onClose, isOpen, title, dashboardId, isEditMode }: ViewModalProps) => {
  const { t } = useTranslation("insights");
  const {
    state: { columns, filters, order, view },
    actions: { setView },
  } = useDashboardContext();
  const [name, setName] = useState<string>(isEditMode ? view?.label ?? "" : "");
  const [filterPreset] = useCreateFilterPresetMutation();

  const [updateFilterPreset] = useUpdateFilterPresetMutation();

  const convertedFilters = filters.map((filter) => ({
    label: filter.label,
    value: filter.value,
    labelColumn: cubeDimensionToKey(filter.labelDimension),
    valueColumn: cubeDimensionToKey(filter.valueDimension),
  }));

  const handleSubmit = async () => {
    await filterPreset({
      dashboardId: dashboardId,
      name: name,
      columns: columns,
      filters: convertedFilters,
      order: /* istanbul ignore next */ order ?? [],
    });
    onClose();
  };

  const handleUpdate = async () => {
    if (!view) {
      return;
    }

    await updateFilterPreset({
      id: view.id,
      name: name,
      columns: columns,
      filters: convertedFilters,
      order: /* istanbul ignore next */ order ?? [],
      dashboardId: dashboardId,
    });

    setView({
      ...view,
      label: name,
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} testId="view-modal">
      <form
        className={styles.modalContent}
        onSubmit={(e) => {
          e.preventDefault();
          void (isEditMode ? handleUpdate() : handleSubmit());
        }}
      >
        <h3 className={styles.modalTitle}>
          <Icon icon="floppyDisk" />
          {title}
        </h3>
        <Input
          type="text"
          name="name"
          label={t("general.view_name_label")}
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
        <div className={styles.modalButtonsWrapper}>
          <Button variant="primary" size="medium" type="submit">
            {t("general.button_save")}
          </Button>
          <Button variant="subtle" size="medium" onClick={onClose}>
            {t("general.button_cancel")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
