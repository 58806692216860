import { InvoiceMethod, useFeatureFlag } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { UniqueComponentId } from "primereact/utils";
import { useMemo } from "react";
import { CanRegisterToggleHeader } from "./CanRegisterToggleHeader/CanRegisterToggleHeader";
import { IsPlannableRadioButton } from "./fields/IsPlannableRadioButton";
import { FormikErrors } from "./FormikErrors";
import { HourTypeGrid } from "./grids/HourTypeGrid";
import { HourTypesTotalsInfo } from "./grids/HourTypeGrid/HourTypeTotalsInfo/HourTypesTotalsInfo";
import { UseProjectServiceFormReturnType } from "./hooks/useProjectServiceForm";
import styles from "./ProjectServicePageHourSection.module.scss";

type HourTypeSectionProps = {
  hourTypeHandlers: UseProjectServiceFormReturnType["hourTypeHandlers"];
  values: UseProjectServiceFormReturnType["values"];
  touched: UseProjectServiceFormReturnType["touched"];
  isDefaultServiceUndefined: boolean;
  errors: UseProjectServiceFormReturnType["errors"];
};

export const HourTypeSection = ({
  hourTypeHandlers: handlers,
  values,
  touched,
  isDefaultServiceUndefined,
  errors,
}: HourTypeSectionProps) => {
  const invoiceMethodIsTimeAndExpenses = values.invoiceMethod === InvoiceMethod.time_and_expenses;
  const hasResourcePlanner = useFeatureFlag("resource-planner").enabled;
  const isHourTypeGridDisabled = !values.canRegisterHours || isDefaultServiceUndefined;

  const isCanRegisterHoursLocked = useMemo(
    () =>
      (values.hourTypes?.some(({ hasRegistrations }) => hasRegistrations) ?? false) &&
      (values.canRegisterHours ?? false),
    [values.canRegisterHours, values.hourTypes],
  );

  const { t } = useTranslation("project_services");

  return (
    <>
      <CanRegisterToggleHeader
        isDefaultServiceUndefined={isDefaultServiceUndefined}
        canRegister={values.canRegisterHours ?? false}
        setCanRegister={handlers.setCanRegisterHours}
        isCanRegisterLocked={isCanRegisterHoursLocked}
        labelText={t("hour_registration")}
        testId="hour_registration_toggle"
        tooltipText={t("cannot_disable_can_register_hours")}
        errors={[
          <FormikErrors touched={touched.canRegisterHours} error={errors?.hourTypes} key={UniqueComponentId()} />,
          <FormikErrors
            touched={touched.canRegisterHours}
            key={UniqueComponentId()}
            error={errors?.canRegisterHours}
          />,
        ]}
      />
      {hasResourcePlanner && (
        <IsPlannableRadioButton
          value={values.isPlannable ?? false}
          onChange={handlers.setIsPlannable}
          disabled={isHourTypeGridDisabled || values.hasAssignments}
          tooltip={values.hasAssignments ? t("is_plannable_locked_tooltip") : undefined}
        />
      )}
      <div className={styles.hourTypeGridContainer}>
        <HourTypeGrid
          value={values.hourTypes}
          onAmountChange={handlers.setAmountForHourType}
          onHourlyRateChange={handlers.setHourlyRateForHourType}
          onIsInvoiceableToggled={handlers.toggleIsInvoiceableForHourType}
          onAddNewHourTypeEntry={handlers.addHourType}
          onCancelNewHourTypeEntry={handlers.removeNewHourTypeEntry}
          onRemoveHourTypeEntries={handlers.removeHourTypes}
          onChangeSpecifiedTotal={handlers.setHourTypesTotal}
          specifiedTotal={values.hourTypesSpecifiedTotal}
          showIsInvoiceableColumn={invoiceMethodIsTimeAndExpenses}
          disabled={isHourTypeGridDisabled}
        />
        <HourTypesTotalsInfo
          hourTypes={values.hourTypes}
          specifiedTotal={values.hourTypesSpecifiedTotal}
          setSpecifiedTotal={handlers.setHourTypesTotal}
        />
      </div>
    </>
  );
};
