import { BinaryFilter } from "@cubejs-client/core";
import { endOfWeek, startOfWeek } from "date-fns";
import { type Filter, CubeDimension, cubeDimensionToKey, View } from "../../types";

type PersistedColumn = {
  column: string;
  order: number;
  visible: boolean;
};

type FilterValues = Record<string, string[]>;

type ColumnOrder = {
  column: string;
  direction: "asc" | "desc";
};

export type DashboardState = {
  columns: PersistedColumn[];
  filters: Filter[];
  filterValues: FilterValues;
  search: string;
  order?: ColumnOrder[];
  dateRange: {
    start: Date;
    end: Date;
  };
  cubeQuery?: {
    filters: BinaryFilter[];
    order?: [string, "asc" | "desc"][] | Record<string, "asc" | "desc">;
  };
  view?: View;
};

type ResetAction = {
  type: "RESET";
};

type OrderByAction = {
  type: "ORDER_BY";
  key: string;
};

type ApplyFilterForDimensionsAction = {
  type: "APPLY_FILTER_FOR_DIMENSION";
  config: { labelDimension: CubeDimension; valueDimension: CubeDimension };
  values: { label: string; value: string }[];
};

type RemoveFilterAction = {
  type: "REMOVE_FILTER";
  filter: Filter;
};

type SetSearchAction = {
  type: "SET_SEARCH";
  search: string;
};

type SetDateRangeAction = {
  type: "SET_DATE_RANGE";
  dateRange: { start: Date; end: Date };
};

type ToggleColumnAction = {
  type: "TOGGLE_COLUMN";
  id: string;
};

type UpdateColumnsAction = {
  type: "UPDATE_COLUMNS";
  columns: PersistedColumn[];
};

type SetViewAction = {
  type: "SET_VIEW";
  view: View;
};

export type ReducerAction =
  | ApplyFilterForDimensionsAction
  | OrderByAction
  | RemoveFilterAction
  | ResetAction
  | SetDateRangeAction
  | SetSearchAction
  | SetViewAction
  | ToggleColumnAction
  | UpdateColumnsAction;

function createDefaultState(): DashboardState {
  return {
    columns: [],
    filters: [],
    filterValues: {},
    search: "",
    dateRange: {
      start: startOfWeek(new Date()),
      end: endOfWeek(new Date()),
    },
    view: undefined,
  };
}
function hasOrderForColumn(order: ColumnOrder[], targetColumn: string) {
  return order.some(({ column }) => column === targetColumn);
}

function buildOrderingState(
  { order }: Pick<DashboardState, "order">,
  action: OrderByAction,
): Pick<DashboardState, "order"> {
  if (!order || order.length === 0) {
    return {
      order: [{ column: action.key, direction: "asc" }],
    };
  }

  if (!hasOrderForColumn(order, action.key)) {
    return {
      order: [...order, { column: action.key, direction: "asc" }],
    };
  }

  return {
    order: order.map((column) => {
      if (column.column === action.key) {
        return {
          ...column,
          direction: column.direction === "asc" ? "desc" : "asc",
        };
      }

      return column;
    }),
  };
}
function toggleColumn(state: DashboardState, id: string) {
  const { columns } = state;

  if (columns.some((column) => column.column === id)) {
    const updatedColumns = columns.map((column) => ({
      ...column,
      visible: column.column === id ? !column.visible : column.visible,
    }));

    return {
      ...state,
      columns: updatedColumns,
    };
  }

  return {
    ...state,
    columns: [
      ...state.columns,
      {
        column: id,
        order: state.columns.length,
        visible: false,
      },
    ],
  };
}

function updateColumns(stateColumns: PersistedColumn[], actionColumns: PersistedColumn[]): PersistedColumn[] {
  const updatedColumns = stateColumns.map((stateColumn) => {
    const matchingColumn = actionColumns.find((actionColumn) => actionColumn.column === stateColumn.column);

    if (matchingColumn) {
      return {
        ...matchingColumn,
      };
    } else {
      return {
        ...stateColumn,
        visible: false,
      };
    }
  });

  const newColumns = actionColumns.filter(
    (actionColumn) => !stateColumns.some((stateColumn) => stateColumn.column === actionColumn.column),
  );

  return [...updatedColumns, ...newColumns];
}

function dashboardReducer(state: DashboardState, action: ReducerAction): Omit<DashboardState, "cubeQuery"> {
  switch (action.type) {
    case "APPLY_FILTER_FOR_DIMENSION":
      return {
        ...state,
        filters: [
          ...state.filters.filter(
            (filter) => cubeDimensionToKey(filter.valueDimension) !== cubeDimensionToKey(action.config.valueDimension),
          ),
          ...action.values.map((value) => ({
            ...value,
            valueDimension: action.config.valueDimension,
            labelDimension: action.config.labelDimension,
          })),
        ],
      };

    case "RESET":
      return {
        ...state,
        ...createDefaultState(),
      };

    case "ORDER_BY":
      return {
        ...state,
        ...buildOrderingState(state, action),
      };

    case "REMOVE_FILTER":
      return {
        ...state,
        filters: state.filters.filter((filter) => filter !== action.filter),
      };

    case "SET_DATE_RANGE":
      return {
        ...state,
        dateRange: action.dateRange,
      };

    case "SET_SEARCH":
      return {
        ...state,
        search: action.search,
      };
    case "TOGGLE_COLUMN":
      return {
        ...state,
        ...toggleColumn(state, action.id),
      };

    case "UPDATE_COLUMNS":
      return {
        ...state,
        columns: updateColumns(state.columns, action.columns),
      };
    case "SET_VIEW":
      return {
        ...state,
        view: action.view,
        filters: action.view.filters,
        columns: action.view.columns,
        order: action.view.order,
      };
  }
}

function finaliseState(state: DashboardState): DashboardState {
  const filters = [...state.filters].sort(
    (a, b) =>
      cubeDimensionToKey(a.labelDimension).localeCompare(cubeDimensionToKey(b.labelDimension)) ||
      a.label.localeCompare(b.label),
  );

  const uniqueFilterDimensions = new Set(
    state.filters.map(({ valueDimension }) => cubeDimensionToKey(valueDimension)) ?? [],
  );

  return {
    ...createDefaultState(),
    ...state,
    filters,
    filterValues: Object.fromEntries(
      [...uniqueFilterDimensions].map(
        (dimension) =>
          [
            dimension,
            state.filters
              .filter(({ valueDimension }) => cubeDimensionToKey(valueDimension) === dimension)
              .map(({ value }) => value),
          ] as const,
      ),
    ),
    cubeQuery: {
      filters: state.filters.map(({ value, valueDimension }) => ({
        member: cubeDimensionToKey(valueDimension),
        operator: "equals",
        values: [value],
      })),
      order: state?.order?.map(({ column, direction }) => [column, direction]),
    },
  };
}

export function createInitialState(state: Partial<DashboardState>): DashboardState {
  const defaultState = createDefaultState();

  return finaliseState({
    columns: state.columns ?? defaultState.columns,
    filters: state.filters ?? defaultState.filters,
    filterValues: state.filterValues ?? defaultState.filterValues,
    search: state.search ?? defaultState.search,
    order: state.order ?? defaultState.order,
    dateRange: state.dateRange ?? defaultState.dateRange,
    view: state.view ?? defaultState.view,
  });
}

export function dashboardStateReducer(state: DashboardState, action: ReducerAction): DashboardState {
  const newState = dashboardReducer(state, action);

  return finaliseState(newState);
}
