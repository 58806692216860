import { useTranslation } from "@simplicate/translations";
import { Clickable, Dropdown, Icon } from "@simplicate/ui";
import { colorCommonGreen500 } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { View } from "../../types";
import { useFilterPresets } from "../../utils";
import { useDashboardContext } from "../Dashboard";
import { ViewModal } from "../ViewModal";
import styles from "./ViewMenu.module.scss";

type ViewMenuProps = {
  dashboardId: string;
};

export const ViewMenu = ({ dashboardId }: ViewMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const views = useFilterPresets(dashboardId);
  const {
    actions: { setView },
    state: { view: currentView },
  } = useDashboardContext();

  const [selectedView, setSelectedView] = useState<View | undefined>(currentView);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation("insights");

  const handleViewChange = (viewId: number) => {
    const selectedView = views.find((view) => view.id === viewId);

    if (selectedView) {
      const updatedFilters = selectedView.filters.map((filter) => ({
        ...filter,
        valueDimension: filter.valueColumn,
        labelDimension: filter.labelColumn,
      }));

      const updatedView = {
        ...selectedView,
        filters: updatedFilters,
      };

      setSelectedView(updatedView);
      setView(updatedView);
    }

    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedView(currentView);
  }, [currentView]);

  return (
    <>
      <div className={styles.wrapper}>
        <Clickable onClick={() => setIsOpen(!isOpen)} testId="view-menu-button">
          <div className={styles.viewMenuButton}>
            <Icon icon="tableLayout" />
            <div className={styles.text}>{selectedView?.label}</div>
            <Icon icon="angleDown" />
          </div>
        </Clickable>

        {isOpen && (
          <div className={styles.dropdownContainer}>
            <Dropdown
              isOpen={isOpen}
              width="auto"
              variant="clean"
              onClickOutside={/* istanbul ignore next */ () => setIsOpen(false)}
            >
              <div className={styles.dropdownContent}>
                <div className={styles.viewContent}>
                  <span className={styles.listHeader}>{t("general.view_menu_placeholder")}</span>
                  {views.map(({ id, label }) => (
                    <Clickable
                      key={id}
                      testId={`view-menu-${id}`}
                      onClick={() => id && handleViewChange(id)}
                      title={label}
                      className={styles.listItem}
                    >
                      {selectedView?.id === id ? (
                        <Icon icon="check" color={colorCommonGreen500} testId="check-icon" />
                      ) : (
                        <div className={styles.checkPlaceholder} />
                      )}
                      {label}
                    </Clickable>
                  ))}
                </div>
                <div className={styles.viewContent}>
                  <Clickable
                    testId="add-view-button"
                    onClick={() => {
                      setIsEditMode(false);
                      setViewModalOpen(true);
                      setIsOpen(false);
                    }}
                    className={classNames(styles.listItem, styles.link)}
                  >
                    <Icon icon="floppyDisk" />
                    {t("general.add_view")}
                  </Clickable>
                </div>
                <div className={styles.viewContent}>
                  <Clickable
                    testId="edit-view-button"
                    onClick={() => {
                      setIsEditMode(true);
                      setViewModalOpen(true);
                      setIsOpen(false);
                    }}
                    className={classNames(styles.listItem, styles.link)}
                  >
                    <Icon icon="pen" />
                    {t("general.edit_view")}
                  </Clickable>
                </div>
              </div>
            </Dropdown>
          </div>
        )}
      </div>

      {viewModalOpen && (
        <ViewModal
          isOpen={viewModalOpen}
          onClose={() => setViewModalOpen(false)}
          title={t("general.view_modal_title")}
          dashboardId={dashboardId}
          isEditMode={isEditMode}
        />
      )}
    </>
  );
};
